import React from 'react';

/* Components */
import { Container, Section } from '../components/components';

const NotFoundPage = () => (
  <Section>
    <Container>
      <h1>Ups! 404 nie ma takiej strony</h1>
      <p>Sprawdź proszę czy:</p>
      <ol>
        <li>wpisałeś poprawny adres strony</li>
        <li>skorzystaj z menu nawigacyjnego aby dotrzeć do szukanej podstrony</li>
      </ol>
    </Container>
  </Section>
);

export default NotFoundPage;
